export const pagesPath = {
  $url: () => `/`,
  tool: {
    $url: () => `/tool/`,
  },
  security: {
    $url: () => `/security/`,
  },
  plan: {
    $url: () => `/plan/`,
  },
  news: {
    $url: () => `/news/`,
    _category: (_slug) => ({
      $url: () => `/news/${_slug}/`,
    }),
    _contentId: (_id) => ({
      $url: () => `/news/${_id}/`,
    }),
  },
  download: {
    $url: () => `/download/`,
    confirmation: {
      $url: () => `/download/confirmation/`,
    },
    thanks: {
      $url: () => `/download/thanks/`,
    },
  },
  downloadLp: {
    $url: () => `/download-lp/`,
    confirmation: {
      $url: () => `/download-lp/confirmation/`,
    },
    thanks: {
      $url: () => `/download-lp/thanks/`,
    },
  },
  downloadIpo: {
    $url: () => `/download-ipo/`,
    confirmation: {
      $url: () => `/download-ipo/confirmation/`,
    },
    thanks: {
      $url: () => `/download-ipo/thanks/`,
    },
  },
  contact: {
    $url: () => `/contact/`,
    confirmation: {
      $url: () => `/contact/confirmation/`,
    },
    thanks: {
      $url: () => `/contact/thanks/`,
    },
  },
  contactLp: {
    $url: () => `/contact-lp/`,
    confirmation: {
      $url: () => `/contact-lp/confirmation/`,
    },
    thanks: {
      $url: () => `/contact-lp/thanks/`,
    },
  },
  contactUser: {
    $url: () => `/contact-user/`,
    confirmation: {
      $url: () => `/contact-user/confirmation/`,
    },
    thanks: {
      $url: () => `/contact-user/thanks/`,
    },
  },
  demo: {
    $url: () => `/demo/`,
    confirmation: {
      $url: () => `/demo/confirmation/`,
    },
    thanks: {
      $url: () => `/demo/thanks/`,
    },
  },
  demoLp: {
    $url: () => `/demo-lp/`,
    confirmation: {
      $url: () => `/demo-lp/confirmation/`,
    },
    thanks: {
      $url: () => `/demo-lp/thanks/`,
    },
  },
  estimateBasic: {
    $url: () => `/estimate-basic/`,
    confirmation: {
      $url: () => `/estimate-basic/confirmation/`,
    },
    thanks: {
      $url: () => `/estimate-basic/thanks/`,
    },
  },
  estimateBasicLp: {
    $url: () => `/estimate-basic-lp/`,
    confirmation: {
      $url: () => `/estimate-basic-lp/confirmation/`,
    },
    thanks: {
      $url: () => `/estimate-basic-lp/thanks/`,
    },
  },
  estimateFull: {
    $url: () => `/estimate-full/`,
    confirmation: {
      $url: () => `/estimate-full/confirmation/`,
    },
    thanks: {
      $url: () => `/estimate-full/thanks/`,
    },
  },
  estimateFullLp: {
    $url: () => `/estimate-full-lp/`,
    confirmation: {
      $url: () => `/estimate-full-lp/confirmation/`,
    },
    thanks: {
      $url: () => `/estimate-full-lp/thanks/`,
    },
  },
  application: {
    $url: () => `/application/`,
    confirmation: {
      $url: () => `/application/confirmation/`,
    },
    thanks: {
      $url: () => `/application/thanks/`,
    },
  },
  applicationBasic: {
    $url: () => `/application-basic/`,
    confirmation: {
      $url: () => `/application-basic/confirmation/`,
    },
    thanks: {
      $url: () => `/application-basic/thanks/`,
    },
  },
  applicationFull: {
    $url: () => `/application-full/`,
    confirmation: {
      $url: () => `/application-full/confirmation/`,
    },
    thanks: {
      $url: () => `/application-full/thanks/`,
    },
  },
  faq: {
    $url: () => `/faq/`,
  },
  rule: {
    $url: () => `/rule/`,
  },
  lp: {
    $url: (hash) => (hash ? `/lp/` + hash : '/lp/'),
  },
}
